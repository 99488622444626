// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/react-scripts/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card {
    transition: transform 0.2s ease-in-out;
}

/* .card:hover {
    transform: translateY(-5px);
} */

.field-btn{
    background-color: #0d6efd !important;
    border: none;
    padding: 10px;
    width: 50% !important;
}
.field-btn:hover{
    background-color: #0d6efd !important;
}
.fieldagentmange{
margin-top: 50px !important;
}

.cancel-btn{
    background-color: #fff !important;
    border: 2px solid #808080;
    color:#999999;
    font-size: 15px !important;
    padding: 10px 50px;
    /* width: 50% !important;*/
    /* float: left; */
    margin-left: 10px;
}

.fieldadd-btn:hover{
    background-color: #0d6efd !important;
  
}

.cancel-btn:hover{
    /* background-color: #0d6efd !important; */
    border: 2px solid #0d6efd;
    color:#999999 !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/css/FieldAgent.css"],"names":[],"mappings":"AAAA;IACI,sCAAsC;AAC1C;;AAEA;;GAEG;;AAEH;IACI,oCAAoC;IACpC,YAAY;IACZ,aAAa;IACb,qBAAqB;AACzB;AACA;IACI,oCAAoC;AACxC;AACA;AACA,2BAA2B;AAC3B;;AAEA;IACI,iCAAiC;IACjC,yBAAyB;IACzB,aAAa;IACb,0BAA0B;IAC1B,kBAAkB;IAClB,0BAA0B;IAC1B,iBAAiB;IACjB,iBAAiB;AACrB;;AAEA;IACI,oCAAoC;;AAExC;;AAEA;IACI,0CAA0C;IAC1C,yBAAyB;IACzB,wBAAwB;AAC5B","sourcesContent":[".card {\n    transition: transform 0.2s ease-in-out;\n}\n\n/* .card:hover {\n    transform: translateY(-5px);\n} */\n\n.field-btn{\n    background-color: #0d6efd !important;\n    border: none;\n    padding: 10px;\n    width: 50% !important;\n}\n.field-btn:hover{\n    background-color: #0d6efd !important;\n}\n.fieldagentmange{\nmargin-top: 50px !important;\n}\n\n.cancel-btn{\n    background-color: #fff !important;\n    border: 2px solid #808080;\n    color:#999999;\n    font-size: 15px !important;\n    padding: 10px 50px;\n    /* width: 50% !important;*/\n    /* float: left; */\n    margin-left: 10px;\n}\n\n.fieldadd-btn:hover{\n    background-color: #0d6efd !important;\n  \n}\n\n.cancel-btn:hover{\n    /* background-color: #0d6efd !important; */\n    border: 2px solid #0d6efd;\n    color:#999999 !important;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
