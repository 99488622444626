import React from "react";
import PropTypes from "prop-types";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";

const CustomAutocomplete = ({
    options = [],
    value,
    onChange = () => {}, // Default no-op handler
    label = "Select",
    placeholder = "Type to search...",
    disableClearable = false,
    sx = {},
    width = "100%",
    getOptionLabel = (option) => (typeof option === "object" ? option?.name || "" : String(option)),
    ...otherProps
}) => {
    return (
        <Autocomplete
            options={options}
            getOptionLabel={getOptionLabel}
            value={value}
            onChange={onChange}
            disableClearable={disableClearable}
            sx={{
                width,
                ...sx,
            }}
            renderInput={(params) => (
                <TextField
                    {...params}
                    label={label}
                    placeholder={placeholder}
                    variant="outlined"
                />
            )}
            {...otherProps}
        />
    );
};

CustomAutocomplete.propTypes = {
    options: PropTypes.array,
    value: PropTypes.any,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string,
    placeholder: PropTypes.string,
    disableClearable: PropTypes.bool,
    sx: PropTypes.object,
    width: PropTypes.string,
    getOptionLabel: PropTypes.func,
};

CustomAutocomplete.defaultProps = {
    options: [],
    label: "Select",
    placeholder: "Type to search...",
    disableClearable: false,
    sx: {},
    width: "100%",
    getOptionLabel: (option) => (typeof option === "object" ? option?.name || "" : String(option)),
};

export default CustomAutocomplete;
